// @import 'tailwindcss/base';

// @import 'tailwindcss/components';

@import "assets/scss/base/fonts.scss";

@import "tailwindcss/utilities";
/* You can add global styles to this file, and also import other style files */

@import "./assets/scss/utils/variables";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/bootstrap/scss/bootstrap";

@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "./assets/scss/style";
@import "./assets/scss/responsive.scss";

.router-wrapper {
	position: relative;
	overflow: hidden;
	width: 100vw;
	height: calc(100% - 47px);
	perspective: 1200px;
	transform-style: preserve-3d;
}

:host {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background: #60d7a9;
}
.iban-request-modal {
	width: 42rem;
}
.s2s-request-modal,
.control-wallet-transfer-modal {
	width: 42rem;
}
.swal-rtl {
	direction: rtl;
}
.toast-container{
	position: fixed !important;
}
