.registration {
    input {
        align-items: center;
        background-color: #ECEFF2;
        height: 56px;
        border-radius: 8px;
        border: 0;
        font-size: 1.2em;
        color: #212529;

        &:focus {
            background-color: #ECEFF2;
            box-shadow: none;
        }

    }

    label {
        font-size: 1.5em;
    }

    .input-group-text {
        border: none;
    }

    // ng-select
    .ng-select.select .ng-select-container {
        align-items: center;
        height: 56px;
        background-color: #ECEFF2;
        border-radius: 8px;
        border: 0;
        font-size: 1.2em;
        line-height: 1.2em;
        color: #212529;
    }

    .ng-option {
        font-size: 1.2em;
        background-color: white !important;
    }

    .ng-select.ng-touched .ng-select-container {
        border-color: #ccc;
    }

    // on focus
    .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
        border-color: #ccc;
        box-shadow: unset;
    }

    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
        top: unset;
    }


}