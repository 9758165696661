/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://angular.pixelstrap.com/cuba
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------


 */

/* ---------------------
	*** Utils ***
-----------------------*/


:root {
    --theme-deafult: #009877;
    --theme-secondary: #FFD100;
}

/* ---------------------
	*** Icons Scss ***
-----------------------*/


// @import "vendors/themify";


/* ---------------------
	*** Base ***
-----------------------*/

@import "base/reset";
@import "base/typography";


/* ---------------------
	*** Components ***
-----------------------*/

// @import "components/according.scss";
@import "components/alert.scss";
// @import "components/avatars.scss";
// @import "components/badge.scss";
@import "components/breadcrumb.scss";

@import "components/buttons.scss";

@import "components/card.scss";
// @import "components/color.scss";
// @import "components/dropdown.scss";
// @import "components/form-input.scss";

@import "components/forms.scss";
// @import "components/icons.scss";
// @import "components/list.scss";
@import "components/loader.scss";


// @import "components/radio.scss";

// @import "components/switch.scss";
@import "components/tab.scss";
@import "components/table.scss";
// @import "components/touchspin.scss";

@import "components/angular-plugin.scss";
// @import "components/calendar.scss";


@import "pages/dashboard_default.scss";

@import "components/_registration.scss";


/* ---------------------
	*** Themes ***
-----------------------*/


/* ---------------------
	*** Layout ***
-----------------------*/


@import "layout/grid.scss";
@import "layout/header.scss";
@import "layout/navs.scss";
@import "layout/sidebar.scss";

// Ngx-Toaster Styles fot LTR/RTL Support
.toast-rtl {
    text-align: end;
}

.toast-ltr {
    text-align: start;
}

.toast-icon-ltr {
    padding: 15px 15px 15px 50px !important;
    background-position: 15px center !important;
}

.toast-icon-rtl {
    padding: 15px 50px 15px 15px !important;
    background-position: right 15px center !important;
}