@font-face {
    font-family: "visbyRoundLight";
    src: url("../../fonts/visby/Webfont/VisbyRoundCF-Light.eot");
    src: url("../../fonts/visby/Webfont/VisbyRoundCF-Light.woff") format("woff")
  }
  
  @font-face {
    font-family: "visbyRoundRegular";
    src: url("../../fonts/visby/Webfont/VisbyRoundCF-Regular.eot");
    src: url("../../fonts/visby/Webfont/VisbyRoundCF-Regular.woff") format("woff")
  }
  
  @font-face {
    font-family: "visbyRoundBold";
    src: url("../../fonts/visby/Webfont/VisbyRoundCF-Bold.eot");
    src: url("../../fonts/visby/Webfont/VisbyRoundCF-Bold.woff") format("woff")
  }
  
  
  @font-face {
    font-family: "currency";
    src: url("../../fonts/riyal/SAR.woff2") format("woff2");
    src: url("../../fonts/riyal/SAR.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap; /* Ensures text is visible while the font loads */
  }